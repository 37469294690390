<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_equipo_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado de equipos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.empresa_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="empresa in listasForms.empresas"
                        :key="empresa.id"
                        :value="empresa.id"
                      >
                        {{ empresa.razon_social }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">Placa</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.placa"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_equipo" class="col-md-5"
                      >Tipo de equipo</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_equipo"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_equipo in listasForms.tipos_equipos"
                        :key="tipo_equipo.numeracion"
                        :value="tipo_equipo.numeracion"
                      >
                        {{ tipo_equipo.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="modelo" class="col-md-5"> Modelo</label>
                    <input
                      type="number"
                      id="Modelos"
                      v-model="filtros.modelo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="marca" class="col-md-5">Marca</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.marca"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="marca in listasForms.marcas"
                        :key="marca.numeracion"
                        :value="marca.numeracion"
                      >
                        {{ marca.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="propiedad" class="col-md-5">Propiedad</label>
                    <select
                      id="propiedad"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.propiedad"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="propiedad in listasForms.tipos_propiedad"
                        :key="propiedad.numeracion"
                        :value="propiedad.numeracion"
                      >
                        {{ propiedad.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="combustible" class="col-md-5"
                      >Tipo de Combustible</label
                    >
                    <select
                      id="combustible"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.combustible"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="combustible in listasForms.combustible"
                        :key="combustible.numeracion"
                        :value="combustible.numeracion"
                      >
                        {{ combustible.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="empresa_id"
                            v-model="form.empresa_id"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="empresa_id"
                            >Empresa</label
                          >
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="placa1"
                              v-model="form.placa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="placa1"
                              >Placa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_equipo"
                              v-model="form.tipo_equipo"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_equipo"
                              >Tipo de equipo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="modelo"
                              v-model="form.modelo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="modelo"
                              >Modelo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="peso"
                              v-model="form.peso"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="peso"
                              >Peso</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="marca"
                              v-model="form.marca"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="marca"
                              >Marca</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="propiedad1"
                              v-model="form.propiedad"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="propiedad1"
                              >Tipo de Propiedad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="combustible1"
                              v-model="form.combustible"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="combustible1"
                              >Tipo de Combustible</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lista_documentos"
                              v-model="form.lista_documentos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lista_documentos"
                              >Lista de Documentos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_creacion"
                              v-model="form.fecha_creacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_creacion"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_modificacion"
                              v-model="form.fecha_modificacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_modificacion"
                              >Fecha Modificación</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.equipos.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
//import vSelect from "vue-select";
export default {
  name: "EquipoExport", //llegada tambien
  components: { Loading },
  data() {
    return {
      cargando: false,
      form: {
        placa: true,
        empresa_id: true,
        tipo_equipo: true,
        modelo: true,
        peso: true,
        marca: true,
        estado: true,
        propiedad: true,
        combustible: true,
        lista_documentos: true,
        fecha_creacion: true,
        fecha_modificacion: true,
      },
      filtros: {
        placa: null,
        tipo_equipo: null,
        modelo: null,
        marca: null,
        estado: null,
        propiedad: null,
        combustible: null,
        empresa_id: null,
      },

      listasForms: {
        tipos_equipos: [],
        marcas: [],
        estados: [],
        tipos_propiedad: [],
        combustible: [],
      },
    };
  },

  methods: {
    /*    async getTiposVehiculos() {
      await axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    }, */
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    async getTiposEquipos() {
      await axios.get("/api/lista/121").then((response) => {
        this.listasForms.tipos_equipos = response.data;
      });
    },

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getEstado() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    async getTiposPropiedad() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.tipos_propiedad = response.data;
      });
    },
    async getCombustibles() {
      await axios.get("/api/lista/24").then((response) => {
        this.listasForms.combustible = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/equipos/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.getTiposEquipos();
    await this.getMarcas();
    await this.getEstado();
    await this.getTiposPropiedad();
    await this.getCombustibles();
    this.cargando = false;
  },
};
</script>
